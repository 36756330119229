.main-not-found {
	display: flex;
	justify-content: center;
}

.not-found {
	display: flex;
	flex-direction: column;
	background-color: #fff;
	justify-content: center;
}

.not-found-message {
	align-self: center;
	margin: 0 auto;
	font-size: 30px;
}	

.not-found-image {
	width: 100px;
	margin: 0 auto 40px;
}