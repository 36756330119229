$sticky-header: -110px;
$basic-color: #0066FF;

.header {
	position: sticky !important;
	top: $sticky-header;
	z-index: 1;
}

.header-top {
	display: flex;
	justify-content: space-between;
	background-color: #fff;
	color: $basic-color;
	padding: 15px;
}

.logo {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.logo__image {
	height: 80px;
	margin: 0 20px;
}

.header-title {
	align-self: center;
}

.header-contacts {
	display: flex;
	width: 255px;
	flex-direction: column;
	text-align: center;
	align-self: center;	
	align-items: center;
	font-size: 17px;
	transition: 0.3s;
}

.header-contacts__button {
	padding: 5px 12px;
	text-align: center;
	border-radius: 20px;
	color: #fff;
	background-color: $basic-color;
	font-size: 18px;
	cursor: pointer;

}

.header-contacts-info {
	animation-name: contactsMoving;
	animation-duration: 500ms;
	@keyframes contactsMoving {
		0% {
			opacity: 0.5;
			transform: translate(20px, 0px);
		}
		100% {
			opacity: 1;
			transform: translate(0px, 0px);
		}
	}
}


.header-contacts__item {
	font-family: 'Ubuntu', sans-serif;
	margin-top: 4px;
}

.nav {
	background-color: $basic-color;	
}

.nav-menu {
	display: flex;
	justify-content: space-between;
	padding: 15px 30px;

}

.nav-menu__item {
	color: #fff;
	transition: 0.3s ease;
	font-family: 'Ubuntu', sans-serif;
}

.nav-menu__item--active {
	display: block;
	@extend .nav-menu__item;
	// &:after {
	// 	content: "";
	// 	display: block;
	// 	width: inherit;
	// 	background-color:#fff;
	// 	height: 2px;
	// 	margin: auto;
	// 	border-radius: 5px;
	// 	animation: chapter-active 0.4s ease;
	// }
}