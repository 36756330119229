/* Large desktops and laptops */
@media (min-width: 1200px) and (max-width: 1280px) {

}
/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
	.teachers-elem {
		display: flex;
		flex-flow: column;
		align-items: center;
	} 

	.teachers-elem {
		font-size: 1.6em;
	}

	.teachers-image {
		width: 80%;
	}

	.teachers-inner {
		margin: 40px;
	}
	.teachers-descript {
		font-size: 25px;
		margin: 0px;
	}
}
/* Portrait tablets and small desktops */
@media (max-width: 991px) {
	.teachers-elem {
		display: flex;
		flex-flow: column;
		align-items: center;
		margin: 40px auto;
	} 

	.teachers-image {
		margin:40px;
	}

	.teachers-image__item {
		height: 400px;
	}
		
	.teachers-descript {
		font-size: 20px;
		padding: 0 40px
	}

}
/* Landscape phones and portrait tablets */
@media (max-width: 767px) { 
	.teachers-image {
		margin:20px;
	}
}
/* Portrait phones and smaller */
@media (max-width: 480px) {
	.teachers-elem {
		display: flex;
		flex-flow: column;
		align-items: center;
		margin: 20px auto;
	} 

	.teachers-image {
		margin:15px;
	}

	.teachers-image__item {
		height: 280px;
	}
		
	.teachers-descript {
		font-size: 12px;
		padding: 0px;	
	}

}

/* Iphone 5 */
@media (max-width: 320px) {

}