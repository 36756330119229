$basic-color: #0066FF;

.main {
	padding: 50px 10px;
	background-color: #fff;
	// height: 100%;
}

.main-description {
	max-width: 1100px;
	margin: 100px auto;
	font-family: 'El Messiri', sans-serif;
	font-size: 20px;
}

.main-description-quote {
	display: flex;
	margin: 50px auto 0px;
	justify-content: space-around;
}	

.main-description-quote__text{
	max-width: 800px;
	text-align: center;
	font-family: 'Caveat', cursive;
	font-size: 30px;
}

.main-description-quote__quotes {
	font-size: 80px;
	color: $basic-color;
	line-height: 80px;
}

.quotes--right {
	transform: rotate(-180deg)
}