.main-directions {
	display: flex;
	flex-direction: column;
	font-family: 'Rubik', sans-serif;
	background-color: #fff;
}

.directions-list-element {
	max-width: 1400px;
	width: 100%;
	margin: 20px auto;
}

.directions-elem {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 40px 0;
}

.directions-elem--active {
	animation-name: showListElem;
	animation-timing-function: easy-out;
	animation-duration: 300ms;
	@keyframes showListElem {
		0% {
			opacity: 0.8;
			transform: translate(0, -20px);
		}
		100% {
			opacity: 1;
			transform: translate(0, 0);
		}
	}
}

.directions-image {
	width: 700px;
	display: flex;
}

.directions-descript { 
	max-width: 700px;
	padding: 0 40px;
	font-family: 'Rubik', sans-serif;
	font-size: 20px;
}

.directions-title {
	display: block;
	margin: 20px 0;
	font-size: 1.3em;
	text-align: center;
}

.directions-text {
	overflow-y: auto;
	white-space: pre-line;
	max-height: 500px;
}

.directions-dropdown{
	display: flex;
	align-items: center;
	margin: 0 25px;
	cursor: pointer;
	-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
	-webkit-tap-highlight-color: transparent;
}

.dropdown-button {
	margin-right: 25px;
	transition: transform 300ms;
}

.dropdown-button--active {
	position: relative;
	z-index: 1;
	transform: rotate(90deg);
}


.dropdown-button__img {

}

.directions-dropdown__text {
	font-size: 2em;
}

.dropdown-button__img {
	height: 17px;
}


.directions-slider {
	display: flex;
	margin-left: 25px; 
}

.directions-slider-body {
	display: flex;
	height: 350px;
	align-items: center;
	margin: 20px 0;
}

.directions-slider-image {
	@extend .directions-image;
	max-width: 350px;
	margin: 0 10px;
	transition: 0.2s ease;
	cursor: pointer;
	transition: 0.4s;
	box-shadow: 3px 3px 25px 1px #000;
	-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
	-webkit-tap-highlight-color: transparent;
}

// .slider-image--next {
// 	animation-name: nextSlide;
// 	animation-duration: 300ms;
// 	animation-timing-function: easy-out;
// 	@keyframes nextSlide {
// 		0% {
// 			opacity: 0.6;
// 			transform: translate(10%, 0px)
// 		}
// 		100% {
// 			opacity: 1;
// 			transform: translate(0px, 0px)
// 		}
// 	}
// }

// .slider-image--prev {
// 	animation-name: prevSlide;
// 	animation-duration: 300ms;
// 	animation-timing-function: easy-out;
// 	@keyframes prevSlide {
// 		0% {
// 			opacity: 0.6;
// 			transform: translate(-10%, 0px)
// 		}

// 		100% {
// 			opacity: 1;
// 			transform: translate(0px, 0px)
// 		}
// 	}
// }

.directions-slider__arrow {
	display: flex;
	align-self: center;
	justify-content: center;
	width: 30px;
	height: 30px;
	border: 3px solid transparent;
	border-radius: 100px;
	user-select: none;
	transition: 0.3s ease;
	&:hover {
		transition: .4s;
		transform: scale(1.2);
	}
}

.directions-slider-zoom {
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0px;
	bottom: 0px;
	left: 0px;
	right: 0px;
	z-index: 10;
	height: 0px;
	width:  0px;
	margin: auto;
	border-radius: 5px;
	opacity: 1;
	background-color: #fff;
	animation-name: directionsSliderMoving;
	animation-duration: 400ms;
	@keyframes directionsSliderMoving {
		0% {
			transform: scale(0.7);
			opacity: 0.5; 
		}
		100% {
			transform: scale(1);
			opacity: 1;
		}
	}
}

.directions-slider__img--zoomed {
	max-width: 90vw;
	max-height: 90vh;
}

