.header-mobile {
	position: sticky !important;
  	top: 0px;
  	z-index: 2;
	display: flex;
	justify-content: space-around;
	background-color: $basic-color;
}

.header-background {
	position: absolute;
}

.logo__image--mobile {
	height: 60px;
	margin: 25px;
}

.header-title--mobile {
	text-align: center;
	font-size: 0.7em;
	color: #fff;
}

.header-burger	{
	padding: 25px;
}

.burger__item {
	display: block;
	height: 8px;
	width: 50px;
	background-color: #fff;
	margin: 8px 0px;
	border-radius: 30px;
}

.nav--mobile {
	position: fixed;
	z-index: 4;
	top: 0px;
	right: 0px;
	text-align: center;
	min-width: 50vw;
	height: 100vh;
	background-color: $basic-color;
	border: 5px solid $basic-color;
	animation-name: menuMobileMoving;
	animation-duration: 400ms ;
	transition-timing-function: ease-out;
	animation-iteration-count: 1;
	animation-direction: alternate;
}

@keyframes menuMobileMoving{
	0% {
		overflow: hidden;
		transform: translate(300px, 0px);	
	}

	100% {
		overflow: hidden;
		transform: translate(0px, 0px)
	}
}

.nav-buttons {
	
}

.nav-buttons-close {
	position: relative;
	margin: 30px 0 0 45px;
	width: 52px;
	height: 52px;
	cursor: pointer;
}

.nav-buttons-close__item{
	display: block;
	width: 45px;
	height: 7px;
	margin: 30px 0;
	border-radius: 10px;
	background-color: #fff;

	&:first-child {
		position: absolute;
		transform: rotate(45deg);
	}

	&:last-child {
		position: absolute;
		transform: rotate(-45deg);
	}
}


.nav-menu--mobile { 
	margin-top: 10px;
}

.nav-menu__item--mobile {
	padding: 20px 0;
	font-size: 1.3em;
	color: #fff !important;
}

.nav-logo--mobile {
	display: flex;
	justify-content: space-around;
	margin: 80px 0;
}

.nav-logo__item--mobile {
	max-height: 70px !important;
}


