.main-archive {
	display: flex;
	flex-direction: column;
	background-color: #fff;
	justify-content: center;
}

.archive-message {
	align-self: center;
	margin: 0 auto;
	font-size: 30px;
}	

.archive-image {
	width: 100px;
	margin: 0 auto 40px;
}