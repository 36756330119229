$basic-color: #0066FF;

.slider {
	display: flex;
	justify-content: center;
}

.slider-body {
	width: 800px;
	margin: 0 40px;
}

.slider-slide{
	display: flex;
	justify-content: center;
}

.slider-points {
	display: flex;
	justify-content: center;
}

.slider-points__item {
	display: block;
	margin: 15px 5px;
	border: 2px solid $basic-color;
	width: 14px;
	height: 14px;
	border-radius: 10px;
	background-color: #fff;
	cursor: pointer;
	&:hover {
		transform: scale(1.1);
		transition: 0.2s;
	}	
}

.slider-points__item--active {
	@extend .slider-points__item;
	background-color: $basic-color;
}

.slider-arrow {
	display: flex;
	align-self: center;
	justify-content: center;
	width: 60px;
	height: 60px;
	border: 3px solid transparent;
	border-radius: 100px;
	user-select: none;
	transition: 0.3s ease;
	&:hover {
		transition: .4s;
		transform: scale(1.2);
	}
}

.arrow-inner {
	align-self: center;
	height: 30px;
}

