/* Large desktops and laptops */
@media (min-width: 1200px) and (max-width: 1280px) {

}
/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {

}
/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) { 

}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) { 
	.aboutus-elem {
		display: flex;
		flex-direction: column;
		max-width: 100%;
		margin: 40px auto;
	}

	.aboutus__image {
		max-height: 350px;
		margin: 20px auto;
	}

	.aboutus-descript {
		max-width: 100%;
		margin: 20px 50px 0;
		text-align: center;
	}
	.aboutus-descript-title {
		
	}

}
/* Portrait phones and smaller */
@media (max-width: 480px) {
	.aboutus-elem {
		margin: 10px auto;
	}
	.aboutus__image {
		max-height: 250px;
		margin: 10px auto;
	}
	.aboutus-descript {
		font-size: 12px;
		line-height: 1.2em;
	}
}
/* Iphone 5 */
@media (max-width: 320px) {

}