/* Large desktops and laptops */
@media (min-width: 1200px) and (max-width: 1280px) {


}
/* Landscape tablets and medium desktops */
@media (max-width: 1199px) {
	.main-description {
		text-align: center;
		margin: 60px 30px;
	}
}
/* Portrait tablets and small desktops */
@media (max-width: 991px) { 
	.main-description {
		text-align: center;
		margin: 60px 30px 0px;
		font-size: 25px;
		line-height: 30px;
	}

}
/* Landscape phones and portrait tablets */
@media (max-width: 767px) { 
	.main-description {
		margin: 40px auto;
		font-size: 15px;
		line-height: 23px;
	}

	.main-description-quote__text {
		max-width: 80vw;
		font-size: 20px;
	}

}
/* Portrait phones and smaller */
@media (max-width: 480px) {

}
/* Iphone 5 */
@media (max-width: 320px) {

}