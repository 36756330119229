$basic-color: #0066FF;

.publications-chapters{
	display: flex;
	justify-content: center;
}

.main-publications{
	background-color: #fff;
	// min-height: calc(100vh - 159px - 45px);
}

.publication__year {
	font-size: 1.3em;
	text-align: center;
}

.publication {
	margin: 30px 40px;
}

.publications-chapters{
	display: flex;
	justify-content: space-between;
	padding: 30px 0 75px;
}

.publications-nav {
	display: flex;
	flex-grow: 2;
	align-self: center;
}

.publications-search {
	display: flex;
	flex-grow: 1;
	justify-content: right;
	align-self: center;
	margin: 0 20px;
}

.search-field {
	height: 40px;
	max-width: 200px;
	border: 2px solid #000;
	border-radius: 5px;
	padding: 0 10px;
	font-size: 20px;
	transition: 0.3s ease;
	&:focus {
		transform: scale(1.05);
		transition: 0.3s ease;
		outline: none;
		border: 2px solid $basic-color;
	}
}


.publications-nothing-found {
	text-align: center;
	font-size: 30px;
	margin: 50px auto;
}

.main-publications-content{
	justify-content: center;
}

.publications-mantions-content {
	width: 100%;
}

.publications-nav__item{
	max-width: 150px;
	margin:0px 50px 0 30px;
	justify-content: center;
	text-align: center;
	font-weight: bold;
	font-size: 1.5em;	
}

.publications-nav__item--active {
	color: $basic-color;
	justify-content: center;
	&:after {
		content: "";
		display: block;
		width: inherit;
		height: 3px;
		margin: auto;
		border-radius: 10px;
		background: $basic-color;
		animation: chapter-active 0.4s ease;
		// transition: 0.6 ease;
	}
}

@keyframes chapter-active {
	0% {
		width: 0px;
	}
	100% {
		width: 100%;
	}
}


.main-publications-content {
	padding: 30px 0;
}

.publication__name{
	font-size: 1.3em;
	font-weight: bold;
}

.publication__item{
	margin: 5px 0;
}

.publications-books{
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	justify-content: center;
}


.book {
	display: flex;
	justify-content: center;
	margin: 20px 0;
	cursor: pointer;
	transition: 0.2s ease;
	&:hover{
		transform: scale(1.05);
		transition: 0.2s ease;

	}
	-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
	-webkit-tap-highlight-color: transparent;
}

.book__img {
	height: 300px;
	box-shadow: 3px 3px 25px 1px #000;
}

.book-zoom-info {
	position: fixed;
	display: flex;
	top: 0px;
	bottom: 0px;
	left: 0px;
	right: 0px;
	z-index: 10;
	width: 850px;
	height: 400px;
	max-height: 95vh;
	margin: auto;
	border-radius: 5px;
	text-align: center;
	opacity: 1;
	background-color: #fff;
	animation-name: bookInfoMoving;
	animation-duration: 300ms;
	animation-iteration-count: 1;
	animation-direction: alternate;
	@keyframes bookInfoMoving {
		0% {
			transform: translate(0px, 100px);
			opacity: 0.6;
		}
		100% {
			transform: translate(0px, 0px);
		}
	}
}

.book-zoom__description{
	font-size: 1.2em;	
}

.book-zoom__item {
	align-self: center;
	margin: 0 30px;
}

