@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital@1&display=swap');

@import "Components/Main/Main.scss";
@import "Components/Main/MainMedia.scss";

@import "Components/Header/Header.scss";
@import "Components/Header/HeaderMedia.scss";
@import "Components/Header/HeaderMobile.scss";
@import "Components/Header/HeaderMobileMedia.scss";

@import "Components/Footer/Footer.scss";
@import "Components/Footer/FooterMedia.scss";

@import "Components/SliderMain/Slider.scss";
@import "Components/SliderMain/SliderMedia.scss";

@import "Components/Directions/Directions.scss";
@import "Components/Directions/DirectionsMedia.scss";

@import "Components/Publications/Publications.scss";
@import "Components/Publications/PublicationsMedia.scss";

@import "Components/Teachers/Teachers.scss";
@import "Components/Teachers/TeachersMedia.scss";

@import "Components/Archive/Archive.scss";

@import "Components/AboutUs/AboutUs.scss";
@import "Components/AboutUs/AboutUsMedia.scss";

@import "Components/NotFound/NotFound.scss";


//Fonts

@import url('https://fonts.googleapis.com/css2?family=El+Messiri:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@500&display=swap');

$basic-color: #0066FF;

* { 
  padding: 0px;
  margin: 0px;
  border: 0px;
  box-sizing: border-box;
}

html {
  overflow-x: hidden;
  height: 100vh;
  // margin-right: calc(-1 * (100vw - 100%));
  margin-right: calc(-1 * (100vw - 100%));
  @media (max-width: 1199px) {
    margin-right: 0px;
    overflow-x: hidden;
  }
}


body {
  font-family: 'Roboto', sans-serif;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  background-color: #E5E5E5;
}


img  {
  display: block;
}

a {
  color: #000;
  text-decoration: none;
}

ul {
  list-style: none;
}

.container {
  position: static;
  margin: 0 auto;
  max-width: 1440px;
  width: 100%;
}

main {
  min-height: calc(100vh - 159px - 45px);
}

/* Landscape tablets and medium desktops */
@media (max-width: 1199px) {
  main {
    min-height: calc(100vh - 110px - 56px);
  }
}
/* Landscape phones and portrait tablets */
@media (max-width: 767px) { 
  main {
    min-height: calc(100vh - 64px - 45px);
  }
}
/* Portrait phones and smaller */
@media (max-width: 480px) {
 main {
    min-height: calc(100vh - 64px - 30px);
  }
}

.shadowBack {
  position: fixed;
  z-index: 2;
  background-color: #000;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  opacity: 0.85;
  overflow: hidden;
}

.inactive {
  display: none;
  visibility: hidden; 
}


