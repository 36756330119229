.main-aboutus {
	display: flex;
	flex-direction: column;
	background-color: #fff;
}

.aboutus-elem {
	display: flex;
	max-width: 95%;
	align-items: center;
	margin: 40px auto;
}

.aboutus__image {
	max-height: 450px;
	margin: 25px auto;
	box-shadow: 2px 2px 25px 1px #000;
	animation-name:aboutUsImgMoving;
	animation-duration: 400ms;
	@keyframes aboutUsImgMoving{
		0% {
			opacity: 0.6;
			transform: translate(-60px, 0);
		}
		100% {
			opacity: 1;
			transform: translate(0, 0);
		}
	}
}

.aboutus-descript {
	max-width: 50%;
	text-align: center;
	font-size: 20px;
	line-height: 1.3em;
	text-align: left;
	animation-name:aboutUsDescMoving;
	animation-duration: 400ms;
	@keyframes aboutUsDescMoving{
		0% {
			opacity: 0.6;
			transform: translate(60px, 0);
		}
		100% {
			opacity: 1;
			transform: translate(0, 0);
		}
	}
}

.aboutus-descript__text {
	margin: 10px auto 20px;
}