	/* Landscape tablets and medium desktops */
	@media (min-width: 992px) and (max-width: 1199px) {
		.footer-copyright {
			margin: 0 10px;
			font-size: 15px;
		}
		.footer-socials__item {
			height: 35px;
			margin: 0 10px;
			transition: 0.2s ease;
			&:hover {
				transform: scale(1.15);
				transition: 0.4s ease-out;
			}
		}
	}
	/* Portrait tablets and small desktops */
	@media (min-width: 768px) and (max-width: 991px) {
		.footer-copyright {
			margin: 0 20px;
			font-size: 15px;
		}

		.footer-address {
			display: none;
		}

		.footer-socials {
			margin: 0 20px;
		}

		.footer-socials__item {

			height: 35px;
			&:hover {
				transform: scale(1.15);
				transition: 0.4s ease-out;
			}
		}
	}
	/* Landscape phones and portrait tablets */
	@media (max-width: 767px) { 
		.footer-copyright {
			margin: 0 8px;
			font-size: 12px;
		}

		.footer-address {
			display: none;
		}

		.footer-socials__item {
			height: 25px;
			margin: 0 7px;
			&:hover {
				transform: scale(1.15);
				transition: 0.4s ease-out;
			}
		}
	}
	/* Portrait phones and smaller */
	@media (max-width: 480px) {
		.footer {
			padding: 6px 0;
		}

		.footer-copyright {
			margin: 0 8px;
			font-size: 10px;
		}
		.footer-socials__item {
			height: 18px;
			margin: 0 5px;
		}
	}

	/* Iphone 5 */
	@media (max-width: 320px) {

	}