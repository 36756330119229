/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) { 
	.header-contacts__item {
		color: #fff;
		font-family: 'Ubuntu', sans-serif;
		margin: 10px 0;
	}
}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) { 
	.nav--mobile {
		min-width: 100vw;
	}

	.nav-menu__item--mobile {
		padding: 20px 0;
		font-size: 1.7em;
		color: #fff !important;
		@keyframes menuMobileMoving{
			0% {
				overflow: hidden;
				transform: translate(0px, 200px)
			}
			100% {
				overflow: hidden;
				transform: translate(0px, 0px)
			}
		}
	}	
	.header-title--mobile {
		text-align: center;
		font-size: 0.4em;
		color: #fff;
	}

	.logo__image--mobile {
		height: 40px;
		margin: 12px 20px;
	}

	.header-burger	{
		padding: 12px 20px;
	}

	.burger__item {
		height: 4px;
		width: 35px;
		margin: 6px 0px;
	}

	.nav-buttons-close {
		margin: 15px 0 0 25px;
		width: 32px;
		height: 32px;
	}
	.nav-buttons-close__item{
		display: block;
		width: 35px;
		height: 4px;
		margin: 20px 0;
	}
	.nav-menu__item--mobile {
		padding: 15px 0;
		font-size: 1.1em;
	}
	.header-contacts-info {
		animation-name: none;
	}
	.header-contacts__item {
		color: #fff;
		font-family: 'Ubuntu', sans-serif;
	}
}
/* Portrait phones and smaller */
@media (max-width: 480px) {

}
/* Iphone 5 */
@media (max-width: 320px) {
	.nav-menu__item--mobile {
		padding: 10px 0;
		font-size: 1em;
	}
	.nav-logo--mobile {
		display: flex;
		flex-direction: column;
		margin: 30px;
	}
	.nav-logo__item--mobile {
		max-height: 50px !important;
		margin: 10px auto;
	}

}