/* Large desktops and laptops */
@media (min-width: 1200px) and (max-width: 1280px) {

}
/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
	.slider-body {
		width: 750px;
		margin: 0 30px;
	}
}
/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) { 
	.slider-body {
		width: 500px;
		margin: 0 25px;
	}
	.slider-points__item {
		margin: 15px 10px;
		border: 4px solid $basic-color;
		width: 20px;
		height: 20px;
		border-radius: 100px;
	}
}
/* Landscape phones and portrait tablets */
@media (max-width: 767px) { 
	.slider-body {
		width: 350px;
		margin: 0 10px;
	}
	.slider-points__item {
		margin: 15px 10px;
		border: 4px solid $basic-color;
		width: 20px;
		height: 20px;
		border-radius: 100px;
	}
	.slider-slide{
		display: flex;
		justify-content: center;
	}
	.slider-slide__image{
		img {
			max-height: 300px;
		}		
	}
	.slider-points__item {
		display: block;
		margin: 15px 5px;
		border: 2px solid $basic-color;
		width: 14px;
		height: 14px;
		border-radius: 10px;
		background-color: #fff;
		cursor: pointer;
		&:hover {
			transform: scale(1.1);
			transition: 0.2s;
		}	
	}
	.slider-points__item--active {
		background-color: $basic-color;
	}
	.arrow-inner {
		align-self: center;
		height: 25px;
	}
}
/* Portrait phones and smaller */
@media (max-width: 480px) {

}
/* Iphone 5 */
@media (max-width: 320px) {
	.slider-body {
		width: 250px;
		margin: 0 10px;
	}
	.slider-slide__image{
		img {
			max-height: 250px;
		}		
	}
}