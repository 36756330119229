.footer {
	display: flex;
	justify-content: space-between;
	padding: 10px 0;
	background-color: #0066FF;
	color: #fff;
}

.footer-address {
	display: flex;
	align-items: center;
	text-align: center;
}

.footer-socials {
	display: flex;
	align-items: center;
	text-align: center;
}

.footer-copyright {
	align-self: center;
	margin: 0 10px;
	font-size: 12px;
}

.footer-socials__item {
	height: 25px;
	margin: 0 10px;
	transition: 0.2s ease;
	&:hover {
		transform: scale(1.15);
		transition: 0.4s ease-out;
	}
	-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
	-webkit-tap-highlight-color: transparent;
}